// Vendor files
var $ = window.jQuery = window.$ = require('./vendor/jquery-1.11.1.min');

var $$_ = window.$$_ = require('./shared/core'); 

var AOS = require('./vendor/aos.js');
var WaveSurfer = require('./vendor/wavesurfer.min.js');

// require('./vendor/chosen.jquery.min'); 

// include your scripts here

// require('./shared/textarea-autosize'); 
// require('./shared/img'); 
// require('./shared/map'); 
// require('./shared/parallax'); 
// require('./shared/select'); 
// require('./shared/search'); 


// template for new JS files, a la browserify
$(function($){
	AOS.init();

	var waveforms = [];

	function pausen(mich) {
		for (var i = 0; i <= waveforms.length - 1; i++) {
			if (i !== mich) {
				waveforms[i].pause();
			}
		}

		$('.waveform_interior').each(function(i) {
			if (i !== mich) {
				$(this).removeClass('playing');
				$(this).addClass('paused');
			}
		});
	}

	console.log($('.waveform'))



	$('.waveform').each(function(i){
		var $that = $(this),
			_id = '#' + $that.attr('id'),
			// _interior = $that.find('.waveform_interior'),
			_file = $that.attr('data-file'),
			_width = Math.floor($that.outerWidth(true)),

			$theBlock = $that.parents('.block'),
			$theCTA = $theBlock.find('p span'),
			$theWaveformInterior = $theBlock.find('.waveform_interior'),
			$thePlaypause = $theBlock.find('.playpause');

			console.log(_width, _width % 2)

		if (_width % 2) {
			_width += 1;
		}

		
		console.log(
			_id,
			// _interior,			
			_file,
			_width
		)

		waveforms[i] = WaveSurfer.create({
			container: _id,
			waveColor: '#bbb',
			progressColor: '#444',
			barHeight: .9,
			height: 50,
			// scrollParent: false,
			maxCanvasWidth: _width
		});

		waveforms[i].load(_file);

		waveforms[i].on('finish', function () {			
			$theWaveformInterior.toggleClass('paused');
			$theWaveformInterior.toggleClass('playing');
		});

		function playThisOne() {

		}

		$theCTA.on('click', function() {
			$theWaveformInterior.addClass('showing');

			setTimeout(function() { 
				console.log('THAT')
				pausen(i);

				$theWaveformInterior.removeClass('paused');
				$theWaveformInterior.addClass('playing');
				// $that.addClass('showing');
				// $that.toggleClass('showing');
				waveforms[i].play();
			}, 1000)
		});

		$thePlaypause.on('click', function(e) {
			console.log('PLAYPAUSE')
			// pausen(i);
			waveforms[i].playPause();
			$theWaveformInterior.toggleClass('paused');
			$theWaveformInterior.toggleClass('playing');
			e.stopPropagation();
		});

		
	});	


	// var wavesurfer02 = WaveSurfer.create({
	//     container: '#waveform02',
	//     waveColor: '#bbb',
	//     progressColor: '#444'
	// });

	// wavesurfer02.load('assets/inc/production.mp3');

	// $('.playpause01').on('click', function() {
	// 	var $that = $(this),
	// 		_number = $that.

	// 		playPause()
	// });

	// $('.playpause01').on('click', function() {
	// 	var $that = $(this),
	// 		_number = $that.

	// 		playPause()
	// });
});
